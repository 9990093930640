import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import '../../assets/scss/partials/_gallery.scss';
import {SectionType} from '../../utils/types/Pages';
import {g6062c, g6101c, g6142c, g6152c} from '../../assets/images';

const Gallery: React.FC<SectionType> = ({anchors}) => {
    let list = [
        [g6062c, 'Meet Medusa', 'Built in 2003 by Azimut and refitted in 2022, accommodating up to eight guests.'],
        [g6142c, 'Unique Interior', 'With a cozy salon in cream and earthy colors and a nice layout of four en-suite cabins.'],
        [g6152c, 'Cruising Experience', 'Three professional crew members are ready to offer their services, and guarantee a unique cruising experience.'],
        [g6101c, 'Luxurious Exterior', 'Enjoy the sun and Greek sea with dining options at the aft and sundeck.'],
    ];
    return (
        <Section className="gallery-section">
            <Padding id="gallery" anchor={anchors.gallery}>
                <h2>Gallery</h2>
                <div className="photographs">
                    <div className="carousel">
                        {list.map((value, index) => {
                            return (
                                <div className={index === 0 ? 'photo first' : 'photo'} key={index}>
                                    <div className="zoom">
                                        <img src={value[0]} alt={value.toString() + ' Photo'} />
                                    </div>
                                    <h3>{value[1]}</h3>
                                    <p>{value[2]}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default Gallery;
