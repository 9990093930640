import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import '../../assets/scss/partials/_crew.scss';
import {SectionType} from '../../utils/types/Pages';
import {crew, c1, c2, c3, c4} from '../../assets/images';
import useWindowSize from '../../utils/hooks/useWindowSize';

interface CrewType extends SectionType {
    mobile: boolean;
}
const Crew: React.FC<CrewType> = ({anchors, mobile}) => {
    const size = useWindowSize().width;
    if ((mobile && size > 1200) || (!mobile && size <= 1200)) {
        return null;
    }
    let list = [
        ['Giorgos Petrou', c1],
        ['Ioannis Vrettos', c2],
        ['Panagiota Ralli', c3],
        ['Kostas Papanasos', c4],
    ];
    return (
        <Section className="crew-section" anchor={anchors.crew}>
            <div className="parallax">
                <div className="background" ref={anchors.crewParallax}>
                    <img src={crew} alt="Crew" />
                </div>
            </div>
            <Padding id="crew">
                <h2>Our Crew</h2>
                <div className="members">
                    {list.map((value, index) => {
                        return (
                            <div className="member" key={index}>
                                <div className="photo">
                                    <div className="zoom">
                                        <img src={value[1]} alt={value[0] + ' Photo'} />
                                    </div>
                                </div>
                                <p>{value[0]}</p>
                            </div>
                        );
                    })}
                </div>
            </Padding>
        </Section>
    );
};
export default Crew;
