import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import '../../assets/scss/partials/_hero.scss';
import {SectionType} from '../../utils/types/Pages';
import {HeroM} from '../../assets/images';
import {Button} from '../../components/Button';
import useWindowSize from '../../utils/hooks/useWindowSize';

const Hero: React.FC<SectionType> = ({anchors}) => {
    const windowSize = useWindowSize();
    const noscroll = windowSize.width <= 1200;
    return (
        <Section className="hero-section">
            <div className="parallax">
                <div className="background" ref={anchors.hero}>
                    <img src={HeroM} alt="Hero" />
                </div>
            </div>
            <Padding id="hero">
                <div className="header ease">
                    <div className="pages">
                        <ul>
                            <li
                                className="under rev ease"
                                onClick={() => {
                                    if (anchors) {
                                        if (!noscroll) {
                                            window.scrollTo(0, anchors.about.current.offsetTop);
                                        } else {
                                            anchors.scroll.current.scrollTo(0, anchors.about.current.offsetTop);
                                        }
                                    }
                                }}>
                                About
                                <span className="ease" />
                            </li>
                            <li
                                className="under rev ease"
                                onClick={() => {
                                    if (anchors) {
                                        if (!noscroll) {
                                            window.scrollTo(0, anchors.gallery.current.offsetTop);
                                        } else {
                                            anchors.scroll.current.scrollTo(0, anchors.gallery.current.offsetTop);
                                        }
                                    }
                                }}>
                                Gallery
                                <span className="ease" />
                            </li>
                            <li
                                className="under rev ease"
                                onClick={() => {
                                    if (anchors) {
                                        if (!noscroll) {
                                            window.scrollTo(0, anchors.specs.current.offsetTop);
                                        } else {
                                            anchors.scroll.current.scrollTo(0, anchors.specs.current.offsetTop);
                                        }
                                    }
                                }}>
                                Specifications
                                <span className="ease" />
                            </li>
                            <li
                                className="under rev ease"
                                onClick={() => {
                                    if (anchors) {
                                        if (!noscroll) {
                                            window.scrollTo(0, anchors.crew.current.offsetTop);
                                        } else {
                                            anchors.scroll.current.scrollTo(0, anchors.crew.current.offsetTop);
                                        }
                                    }
                                }}>
                                Crew
                                <span className="ease" />
                            </li>
                            <li
                                className="under rev ease last"
                                onClick={() => {
                                    if (anchors) {
                                        if (!noscroll) {
                                            window.scrollTo(0, anchors.contact.current.offsetTop);
                                        } else {
                                            anchors.scroll.current.scrollTo(0, anchors.contact.current.offsetTop);
                                        }
                                    }
                                }}>
                                Contact
                                <span className="ease" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="info">
                    <div className="text">
                        <h1>DREAM the Charter Experience</h1>
                        <p>Enjoy Greece, The Right Way</p>
                    </div>
                    <div className="cta">
                        <Button
                            type="light"
                            onClick={() => {
                                if (anchors) {
                                    if (!noscroll) {
                                        window.scrollTo(0, anchors.about.current.offsetTop);
                                    } else {
                                        anchors.scroll.current.scrollTo(0, anchors.about.current.offsetTop);
                                    }
                                }
                            }}>
                            Learn More
                        </Button>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default Hero;
