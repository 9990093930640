import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import '../../assets/scss/partials/_about.scss';
import {SectionType} from '../../utils/types/Pages';
import {intro1, intro2} from '../../assets/images';

const About: React.FC<SectionType> = ({anchors}) => {
    return (
        <Section className="about-section">
            <Padding id="about" anchor={anchors.about}>
                <h2>
                    Your ticket to
                    <br />
                    <span className="second"> the beautiful</span>
                    <br />
                    <span className="third">Seas of Greece</span>
                </h2>
                <div className="wrap">
                    <div className="col">
                        <div className="photo">
                            <img src={intro1} alt="Hero" />
                        </div>
                        <p>
                            A stylish Azimut 68' plus motor yacht with four cabins, all with en-suite facilities accommodating eight guests in one master cabin, one VIP, one twin
                            cabin and one twin cabin with upper and lower beds.
                        </p>
                    </div>
                    <div className="col second">
                        <p>
                            Plenty of exterior areas to enjoy the sun and Greek sea with dining options at the aft and sundeck. Three crew members are ready to offer their service.{' '}
                        </p>
                        <div className="photo">
                            <img src={intro2} alt="Hero" />
                        </div>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default About;
