import React, {MutableRefObject} from 'react';

type PaddingType = {
    anchor?: MutableRefObject<HTMLDivElement>;
    id?: string | undefined;
    children: React.ReactNode;
};

const Padding: React.FC<PaddingType> = ({anchor = null, children, id = undefined}) => {
    return (
        <div ref={anchor} id={id} className="padding">
            {children}
        </div>
    );
};

export default Padding;
