import React from 'react';

type WrapperType = {
    children: React.ReactNode;
};

const Wrapper: React.FC<WrapperType> = ({children}) => {
    return <div id="wrapper">{children}</div>;
};

export default Wrapper;
