import React, {MutableRefObject} from 'react';
import {motion} from 'framer-motion';
import {pageTransition} from '../../utils/data/Global';

type SectionType = {
    anchor?: MutableRefObject<HTMLDivElement>;
    children?: React.ReactNode;
    className?: string;
    id?: string;
};

const Section: React.FC<SectionType> = ({anchor = null, children, id = undefined, className = ''}) => {
    return (
        <motion.div exit={{opacity: 0}} transition={pageTransition} ref={anchor} id={id} className={className}>
            {children}
        </motion.div>
    );
};

export default Section;
