import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import '../../assets/scss/partials/_contact.scss';
import {SectionType} from '../../utils/types/Pages';
import useWindowSize from '../../utils/hooks/useWindowSize';

const Contact: React.FC<SectionType> = ({anchors}) => {
    const windowSize = useWindowSize();
    const noscroll = windowSize.width <= 1200;
    return (
        <Section className="contact-section">
            <Padding id="contact" anchor={anchors.contact}>
                <h2>Contact</h2>
                <div className="wrap">
                    <div className="col long">
                        <div className="row">
                            <h5>Located in Athens, Greece</h5>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <h5>Interested In Medusa?</h5>
                        </div>
                        <div className="row">
                            <p>
                                Marios Messinis
                                <br />
                                Developer / Designer
                            </p>
                        </div>
                    </div>
                    <div className="col right">
                        <div className="row">
                            <a href="mailto:hello@messinismarios.com">
                                <h5 className="under">
                                    Make An Enquiry
                                    <span className="ease" />
                                </h5>
                            </a>
                        </div>
                    </div>
                    <div className="col long end">
                        <div className="row">
                            <p>© 2023 Medusa Charter - All Rights Reserved</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <h5>Get In Touch</h5>
                        </div>
                        <div className="row">
                            <p>
                                <a className="ease" href="tel:+306939116640">
                                    +30 693 9116640
                                </a>
                                <br />
                                <a className="ease" href="mailto:hello@messinismarios.com">
                                    hello@messinismarios.com
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col end right">
                        <div className="row">
                            <p
                                className="pointer"
                                onClick={() => {
                                    if (anchors) {
                                        if (!noscroll) {
                                            window.scrollTo(0, 0);
                                        } else {
                                            anchors.scroll.current.scrollTo(0, 0);
                                        }
                                    }
                                }}>
                                ↑
                            </p>
                        </div>
                    </div>
                    <div className="col footer">
                        <p>© 2023 Medusa Charter - All Rights Reserved</p>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default Contact;
