import React, {useState} from 'react';

import Wrapper from '../containers/partials/Wrapper';
import Layout from '../utils/Layout';

import Hero from '../containers/sections/Hero';
import Scroll from '../containers/partials/Scroll';
import {AnchorType} from '../utils/types/Pages';
import {Helmet} from 'react-helmet-async';
import Gallery from '../containers/sections/Gallery';
import Crew from '../containers/sections/Crew';
import About from '../containers/sections/About';
import Specs from '../containers/sections/Specs';
import Contact from '../containers/sections/Contact';

const Home: React.FC<AnchorType> = ({anchors}) => {
    const [leaving] = useState(false);

    return (
        <>
            <Layout>
                <Helmet>
                    <title>Medusa | Stylish Yacht in Athens, Greece</title>
                    {/* <meta name="og:title" content="Marios Messinis | Developer, Designer & Digital Artist" />
                    <meta name="twitter:title" content="Marios Messinis | Developer, Designer & Digital Artist" />
                    <meta name="og:url" content="https://messinismarios.com" />
                    <meta name="og:image" content="https://messinismarios.com/assets/hero.jpg" />
                    <meta name="twitter:image:src" content="https://messinismarios.com/assets/hero.jpg" />
                    <meta name="image" content="https://messinismarios.com/assets/hero.jpg" />
                    <meta
                        name="description"
                        content="I am Marios, an athens-based developer and designer. I specialise in building advanced back-end applications, web platforms and websites, but also UI/UX, graphic designs and artwork."
                    />
                    <meta
                        name="og:description"
                        content="I am Marios, an athens-based developer and designer. I specialise in building advanced back-end applications, web platforms and websites, but also UI/UX, graphic designs and artwork."
                    />
                    <meta
                        name="twitter:description"
                        content="I am Marios, an athens-based developer and designer. I specialise in building advanced back-end applications, web platforms and websites, but also UI/UX, graphic designs and artwork."
                    /> */}
                </Helmet>
                <Scroll id="home-page" anchors={anchors} leaving={leaving}>
                    <Wrapper>
                        <Hero anchors={anchors} />
                        <About anchors={anchors} />
                        <Gallery anchors={anchors} />
                        <Crew anchors={anchors} mobile={true} />
                        <Specs anchors={anchors} />
                        <Crew anchors={anchors} mobile={false} />
                        <Contact anchors={anchors} />
                    </Wrapper>
                </Scroll>
            </Layout>
        </>
    );
};

export default Home;
