import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import '../../assets/scss/partials/_specs.scss';
import {SectionType} from '../../utils/types/Pages';
import {specs} from '../../assets/images';
import {Button} from '../../components/Button';
import useWindowSize from '../../utils/hooks/useWindowSize';

const Specs: React.FC<SectionType> = ({anchors}) => {
    const windowSize = useWindowSize();
    const noscroll = windowSize.width <= 1200;
    return (
        <Section className="specs-section">
            <Padding id="specs" anchor={anchors.specs}>
                <div className="wrap">
                    <div className="col photo">
                        <img src={specs} alt="Hero" />
                    </div>
                    <div className="col">
                        <h2>Specifications</h2>
                        <h4>
                            1 Master Cabin with queen size bed & walk-in closet, 1 VIP cabin with queen size bed, 1 Twin cabin with two single beds, 1 Twin cabin with upper and
                            lower beds (all cabins have en-suite facilities)
                        </h4>
                        <p>
                            Length: 21.10m (69ft 3in)
                            <br />
                            Beam: 5.45m (17ft 11in)
                            <br />
                            Draft: 2.70m (8ft 10in)
                            <br />
                            Builder: Azimut
                            <br />
                            Year Built: 2003 (2022 Refit)
                            <br />
                            <br />
                            Engines: 2 x 1.150hp
                            <br />
                            MTU Engines fuel consumption: 300
                            <br />
                            Litres/Hr Generators: 1 x 20kw
                            <br />
                            Kohler 1 x 17.5kw
                            <br />
                            Onan Cruising speed: 23 knots
                            <br />
                            Max cruising speed: 28 knots
                        </p>
                        <Button
                            type="dark"
                            onClick={() => {
                                if (!noscroll) {
                                    window.scrollTo(0, anchors.contact.current.offsetTop);
                                } else {
                                    anchors.scroll.current.scrollTo(0, anchors.contact.current.offsetTop);
                                }
                            }}>
                            Learn More
                        </Button>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default Specs;
