import React from 'react';
import {Link} from '../components/Link';
import {SetState} from './types/Global';

type LayoutType = {
    children: React.ReactNode;
};

type PageLinkType = {
    link: string;
    children: React.ReactNode;
    setLeaving: SetState<boolean>;
    click?: () => void;
};

const Layout: React.FC<LayoutType> = ({children}) => {
    return <React.Fragment>{children}</React.Fragment>;
};

export const PageLink: React.FC<PageLinkType> = ({link, children, setLeaving, click}) => {
    return (
        <Link
            onClick={() => {
                window.removeEventListener('mousemove', event => {});
                window.scrollTo(0, 0);
                setLeaving(true);
                click && click();
            }}
            to={link}>
            {children}
        </Link>
    );
};

export default Layout;
