import React from 'react';

type ButtonType = {
    type: 'light' | 'dark';
    onClick?: () => void;
};

export const Button: React.FC<ButtonType> = ({children, type = 'dark', onClick}) => {
    return (
        <div onClick={onClick} className={'btn ease ' + type + '-btn'}>
            {children}
        </div>
    );
};
