import React, {useRef, MutableRefObject} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

import Home from './pages/Home';
import {HelmetProvider} from 'react-helmet-async';

const App = () => {
    const AboutAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const ContactAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const CrewAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const CrewParallaxAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const GalleryAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const HeroAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const SpecsAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const ScrollAnchor = useRef() as MutableRefObject<HTMLDivElement>;
    const Anchors = {
        about: AboutAnchor,
        contact: ContactAnchor,
        crew: CrewAnchor,
        crewParallax: CrewParallaxAnchor,
        gallery: GalleryAnchor,
        hero: HeroAnchor,
        specs: SpecsAnchor,
        scroll: ScrollAnchor,
    };
    const location = useLocation();
    return (
        <HelmetProvider>
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.pathname}>
                    <Route path="*" element={<Home anchors={Anchors} />} />
                </Routes>
            </AnimatePresence>
        </HelmetProvider>
    );
};

export default App;
